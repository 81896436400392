import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f5a837e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "search-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBox = _resolveComponent("SearchBox")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_main = _resolveComponent("el-main")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_main, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_SearchBox, {
          placeholder: "Order Id / E-mail",
          clickSearch: _ctx.searchKeyword
        }, null, 8, ["clickSearch"])
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        data: _ctx.data?.data,
        border: "",
        fit: "",
        "highlight-current-row": "",
        style: {"width":"100%"},
        onSortChange: _ctx.sortChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.id')
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.id), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.orderID'),
            width: "120"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.orderId), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.user')
          }, {
            default: _withCtx(({row}) => [
              _createVNode(_component_router_link, {
                to: `/users/${row.id}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(row.username), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.plan')
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.plan), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.amount'),
            width: "120"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.amount), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.paymentMethod'),
            width: "160"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.paymentMethod), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.transactionNumber'),
            width: "190"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.transactionId), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.paidAt'),
            width: "180"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.paidAt), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.transactionFee'),
            width: "190"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.transactionFee), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.settlementFee'),
            width: "190"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.settlementFee), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.reserveFee'),
            width: "120"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.reserveFee), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.totalAmount'),
            width: "150"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.totalAmount), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.createdAt'),
            width: "180"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.createdAt), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_table_column, {
            align: "center",
            label: _ctx.t('orders.list.updatedAt'),
            prop: "updated_at",
            width: "180",
            sortable: "custom"
          }, {
            default: _withCtx(({row}) => [
              _createElementVNode("span", null, _toDisplayString(row.updatedAt), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["data", "onSortChange"])), [
        [_directive_loading, _ctx.isLoading || _ctx.isFetching]
      ]),
      _createVNode(_component_el_pagination, {
        currentPage: _ctx.page,
        "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.page) = $event)),
        "page-size": _ctx.data?.meta?.perPage,
        layout: "prev, pager, next, jumper",
        total: _ctx.data?.meta?.total
      }, null, 8, ["currentPage", "page-size", "total"])
    ]),
    _: 1
  }))
}